import styled from 'styled-components'
import {
  ActivityWidget,
  ActivityWidgetType
} from '../../../../components/ActivityComponents/ActivityWidget/ActivityWidget'
import {
  SizeEnum,
  WidgetState
} from '../../../../components/DataWidget/Shared/Interfaces/Interfaces'
import { TimeInRange } from '../../../../components/DataWidget/TimeInRange/TimeInRange'
import { TotalInsulin } from '../../../../components/DataWidget/TotalInsulin/TotalInsulin'
import { DataWidgetStats } from '../../../../components/DataWidgetStats/DataWidgetStats'
import { FlexColumnWrapper } from '../../../../components/FlexWrappers/FlexColumnWrapper'
import { PenInjectionsWidget } from '../../../../components/Graphs/PenInjections/PenInjectionsWidget/PenInjectionsWidget'
import {
  PatientViewDisplays,
  usePatientViewsContext
} from '../../../../core/contexts/patient-view-context'
import { getBars } from '../../../../helpers/getBars'
import {
  DaysObject,
  FitbitActivityMaxes,
  insulinDisplays
} from '../../Interfaces/Interfaces'

const DataWidgetWrapper = styled(FlexColumnWrapper)`
  display: flex;
  gap: 1rem;
  z-index: 1;
  width: 18.25rem;
`
interface StackedDayWidgetsProps {
  item: null | DaysObject
  loading: boolean
  maxTotalInsulin: number | null
  fitbitActivityMaxes: FitbitActivityMaxes
}
export const StackedDayWidgets = ({
  item,
  loading,
  maxTotalInsulin,
  fitbitActivityMaxes
}: StackedDayWidgetsProps) => {
  const { patientViews } = usePatientViewsContext()
  const bars = getBars({
    activity: item?.pumpActivity ?? null,
    timeInRange: item?.timeInRange ?? null,
    automation: item?.timeInAutomation ?? null,
    totalInsulin: item?.totalInsulin ?? null,
    maxTotalInsulin: maxTotalInsulin ?? null,
    widgetStack: false
  })

  return (
    <DataWidgetWrapper>
      <TimeInRange
        size={SizeEnum.Small}
        data={bars.timeInRangeBars}
        state={loading ? WidgetState.Loading : WidgetState.Data}
      ></TimeInRange>
      <DataWidgetStats
        averageGlucose={item?.averageGlucose ?? null}
        totalCarbs={item?.totalCarbs ?? null}
        dailyActivity={item?.timeInActivity ?? null}
        state={loading ? WidgetState.Loading : WidgetState.Data}
      ></DataWidgetStats>
      {patientViews?.Daily.subViews.includes(
        PatientViewDisplays.FitbitWidget
      ) && (
        <ActivityWidget
          data={item?.fitbitWidget ?? null}
          fitbitActivityMaxes={fitbitActivityMaxes}
          day={item?.day ?? ''}
          type={ActivityWidgetType.daily}
          state={loading ? WidgetState.Loading : WidgetState.Data}
        />
      )}
      {item?.insulinDisplay !== insulinDisplays.PenInjections ? (
        <TotalInsulin
          size={SizeEnum.Small}
          state={loading ? WidgetState.Loading : WidgetState.Data}
          data={{
            bars: bars.TotalInsulinBars,
            total: item?.totalInsulin?.total ?? null,
            basedOn: item?.totalInsulin
              ? {
                  selectedDays: item.totalInsulin.selectedDays,
                  daysWithData: item.totalInsulin.daysWithData
                }
              : null
          }}
          max={maxTotalInsulin}
        />
      ) : (
        <PenInjectionsWidget
          data={item?.penInjectionsWidget ?? null}
          state={loading ? WidgetState.Loading : WidgetState.Data}
        />
      )}
    </DataWidgetWrapper>
  )
}
