import dayjs from 'dayjs'
import 'dayjs/locale/da'
import 'dayjs/locale/de'
import 'dayjs/locale/en'
import 'dayjs/locale/es'
import 'dayjs/locale/fo'
import 'dayjs/locale/fr'
import 'dayjs/locale/nb'
import 'dayjs/locale/nn'
import 'dayjs/locale/sv'

import dayOfYear from 'dayjs/plugin/dayOfYear'
import isToday from 'dayjs/plugin/isToday'
import isoWeek from 'dayjs/plugin/isoWeek'
import objectSupport from 'dayjs/plugin/objectSupport'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'

dayjs.extend(isoWeek)
dayjs.extend(dayOfYear)
dayjs.extend(updateLocale)
dayjs.extend(isToday)
dayjs.updateLocale('en', {
  monthsShort: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]
})
dayjs.updateLocale('da', {
  monthsShort: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Maj',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dec'
  ],
  weekdaysShort: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør']
})

const config = {
  thresholds: [
    { l: 's', r: 1 },
    { l: 'm', r: 1 },
    { l: 'mm', r: 59, d: 'minute' },
    { l: 'h', r: 1 },
    { l: 'hh', r: 23, d: 'hour' },
    { l: 'd', r: 1 },
    { l: 'dd', r: 364, d: 'day' },
    { l: 'M', r: 1 },
    { l: 'MM', r: 1, d: 'month' },
    { l: 'y', r: 13, d: 'month' },
    { l: 'yy', d: 'year' }
  ]
}
dayjs.extend(relativeTime, config)
dayjs.extend(relativeTime)
dayjs.extend(objectSupport)
export default dayjs
