import { faArrowUpFromArc } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode } from 'react'
import styled from 'styled-components'

const HeaderButtonComponent = styled.button`
  background-color: var(--white-color);
  color: var(--text-primary);
  border: 0.0625rem solid var(--text-lightest);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1rem 0.75rem 0.875rem;
  gap: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  &:hover {
    color: var(--brand-primary-color);
  }
  height: 2.5rem;
  box-sizing: border-box;
`
const Text = styled.div`
  font-family: inter;
  font-weight: 500;
  font-size: 0.875rem;
  color: inherit;
  @media (max-width: 1300px) {
    display: none;
  }
`
const Logo = styled(FontAwesomeIcon)`
  font-size: 1rem;
  font-weight: 400;
  color: inherit;
`
type ButtonProps = {
  inUploader: boolean
  icon: any
  children: ReactNode
  onClick?: () => void
}

export const UploaderButton = ({
  inUploader,
  icon,
  children,
  ...props
}: ButtonProps) => {
  return (
    <HeaderButtonComponent
      {...props}
      style={
        inUploader
          ? {
              backgroundColor: 'var(--brand-primary-4-color)',
              borderColor: 'var(--brand-primary-12-color)',
              color: 'var(--brand-primary-color)'
            }
          : {}
      }
    >
      <Logo icon={faArrowUpFromArc}></Logo>
      <Text>{children}</Text>
    </HeaderButtonComponent>
  )
}
