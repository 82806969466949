import { useTranslation } from 'react-i18next'
import { useSiteSettingsContext } from '../core/contexts/site-settings-context'
import dayjs from '../core/dayjs/dayjs'
import { getDayjsLanguageString } from '../core/dayjs/getDayjsLanguageString'
import { setLanguage } from '../core/i18n'

export function useLanguage() {
  const { i18n } = useTranslation()
  const { siteSettings } = useSiteSettingsContext()

  const changeLanguage = (value: string) => {
    i18n.changeLanguage(value)
    setLanguage(value)
    dayjs.locale(getDayjsLanguageString(value))
  }

  return {
    i18n,
    localization: siteSettings?.localization,
    changeLanguage
  }
}
