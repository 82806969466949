import {
  faArrowDownFromLine,
  faArrowsToLine,
  faArrowUpFromLine,
  faHourglassHalf,
  faSortDown,
  faSortUp,
  faTriangleExclamation
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch, SetStateAction } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TitrationEventChange } from '../../containers/PenTitrations/PenTitrations'
import { DoseRecommendationState } from '../../core/api/getDoseRecommendations'
import { TitrationHistoryObject } from '../../core/api/getTitrationHistory'
import { useSessionContext } from '../../core/contexts/session-context'
import dayjs from '../../core/dayjs/dayjs'
import { UserType } from '../../core/entities/user.entity'
import {
  HeaderWrapper,
  SortEnum,
  SortIconWrapper
} from '../ProviderPatients/ProviderPatients'
import { NoDataRow } from '../Table/NoDataRow'
import {
  ScrollTableComponent,
  Td,
  Th,
  THead,
  Tr,
  TText
} from '../Table/ScrollTableComponent'
import { ScrollTableLoading } from '../Table/ScrollTableLoading.tsx'
import {
  SortIconDown,
  SortIconUp
} from '../Uploads/UploadsHeaderComponent/UploadsHeaderComponent'

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1rem;
  padding-bottom: 3rem;
`
const RowBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const ChangeText = styled(TText)<{ $color: string }>`
  color: ${(props) => props.$color};
`

const ChangeIcon = styled(FontAwesomeIcon)<{ $color: string }>`
  font-size: 1rem;
  line-height: 2.25rem;
  color: ${(props) => props.$color};
`
const TitrationHistoryGroup = ({ isPatient }: { isPatient: boolean }) => {
  return (
    <colgroup data-testid="titrationHistoryGroup">
      <col style={{ width: '11.125%' }} />
      <col style={{ width: '6%' }} />
      {!isPatient && <col style={{ width: '10.75%' }} />}
      <col style={{ width: '16.5%' }} />
      <col style={{ width: '55.625%' }} />
    </colgroup>
  )
}
export const getChangeString = (
  change: TitrationEventChange,
  timeSinceLastTitration: number | null,
  t: TFunction<'translation', undefined>
) => {
  if (change.state === DoseRecommendationState.Cooldown) {
    return (
      <>
        <ChangeIcon icon={faHourglassHalf} $color={'var(--text-lighter)'} />
        <ChangeText $color={'var(--text-lighter)'}>
          {t('paused_days', { days: 7 - (timeSinceLastTitration ?? 0) })}
        </ChangeText>
      </>
    )
  } else if (change.state === DoseRecommendationState.LowSensorCoverage) {
    return (
      <>
        <ChangeIcon
          icon={faTriangleExclamation}
          $color={'var(--yellow-darker)'}
        />
        <ChangeText $color={'var(--yellow-darker)'}>
          {t('not_available')}
        </ChangeText>
      </>
    )
  } else if (change.total < 0) {
    return (
      <>
        <ChangeIcon icon={faArrowDownFromLine} $color={'var(--pink)'} />
        <ChangeText $color={'var(--text-primary)'}>
          {t('Subtract')} {Math.abs(change.total)} U
        </ChangeText>
      </>
    )
  } else if (change.total === 0) {
    return (
      <>
        <ChangeIcon icon={faArrowsToLine} $color={'var(--text-primary)'} />
        <ChangeText $color={'var(--text-primary)'}>{t('No change')}</ChangeText>
      </>
    )
  } else {
    return (
      <>
        <ChangeIcon icon={faArrowUpFromLine} $color={'var(--blue)'} />
        <ChangeText $color={'var(--text-primary)'}>
          {t('Add')} {change.total} U
        </ChangeText>
      </>
    )
  }
}

export const TitrationHistory = ({
  history,
  sorting,
  setSorting
}: {
  history: TitrationHistoryObject[] | null
  sorting: SortEnum
  setSorting: Dispatch<SetStateAction<SortEnum>>
}) => {
  const { user } = useSessionContext()
  const { t } = useTranslation()
  const columns = [
    { name: t('Date'), show: true },
    { name: t('Time'), show: true },
    {
      name: t('new_basal_dose'),
      show: true
    },
    { name: t('change_from_last_dose'), show: user?.type !== UserType.Patient },
    { name: t('prescribed_by'), show: true }
  ]

  return (
    <TableWrapper>
      <ScrollTableComponent
        group={
          <TitrationHistoryGroup isPatient={user?.type === UserType.Patient} />
        }
      >
        <THead data-testid="titrationHistoryHeader">
          {columns.map(
            (key, idx) =>
              key.show && (
                <Th key={key.name}>
                  <HeaderWrapper>
                    <TText>{key.name}</TText>
                    {idx === 0 && (
                      <SortIconWrapper
                        data-testid="sortbutton"
                        onClick={() =>
                          setSorting(
                            sorting === SortEnum.Ascending
                              ? SortEnum.Descending
                              : SortEnum.Ascending
                          )
                        }
                      >
                        <SortIconUp
                          data-testid="sortUp"
                          icon={faSortUp}
                          $active={sorting === SortEnum.Ascending}
                        />
                        <SortIconDown
                          data-testid="sortDown"
                          icon={faSortDown}
                          $active={sorting === SortEnum.Descending}
                        />
                      </SortIconWrapper>
                    )}
                  </HeaderWrapper>
                </Th>
              )
          )}
        </THead>
        {history ? (
          history.length > 0 ? (
            history.map((e) => (
              <Tr
                key={'titrationHistoryEvent' + e.time}
                data-testid="titrationHistoryEventRow"
              >
                <Td>
                  <RowBoxWrapper>
                    <TText>{dayjs(e.time).format('DD/MM/YYYY')}</TText>
                  </RowBoxWrapper>
                </Td>
                <Td>
                  <RowBoxWrapper>
                    <TText>{dayjs(e.time).format('HH:mm')}</TText>
                  </RowBoxWrapper>
                </Td>
                <Td>
                  <RowBoxWrapper>
                    <TText>{e.dose} U</TText>
                  </RowBoxWrapper>
                </Td>
                {user?.type !== UserType.Patient && (
                  <Td>
                    <RowBoxWrapper>
                      {getChangeString(
                        {
                          state: DoseRecommendationState.Ok,
                          total: e.changeFromLastDose
                        },
                        0,
                        t
                      )}
                    </RowBoxWrapper>
                  </Td>
                )}
                <Td>
                  <RowBoxWrapper>
                    <TText>{e.user}</TText>
                  </RowBoxWrapper>
                </Td>
              </Tr>
            ))
          ) : (
            <NoDataRow />
          )
        ) : (
          <ScrollTableLoading size={20} />
        )}
      </ScrollTableComponent>
    </TableWrapper>
  )
}
